
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHAccordion',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    hash: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
    productAccordion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },

  mounted() {
    if (this.open) {
      this.isOpen = true
    }
  },
  computed: {
    /* Remove tags */
    textFormat(): string {
      return this.title.replace(/<\/?[a-zA-Z]+>/gi, '')
    },
    /* Remove &nbsp for content */
    contentFormat(): string {
      return this.$services.common.textFormat.formatText(this.content)
    },
  },
  watch: {
    $route() {
      if (!this.open) {
        this.isOpen = this.$route.hash ? this.$route.hash.slice(1) === this.$props.hash : false
      }
    },
  },
  methods: {
    toggleAccordeon() {
      if (!this.open) {
        this.isOpen = !this.isOpen
      }
    },
    startTransition(el: any) {
      el.style.height = el.scrollHeight + 'px'
    },

    endTransition(el: any) {
      el.style.height = ''
    },
  },
})
