
import Vue from 'vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import RPHAccordion from 'rph/molecules/RPHAccordion/index.vue'

export default Vue.extend({
  name: 'BenefitPage',
  components: {
    RPHAccordion,
    RPHButton,
  },

  async asyncData({ store }) {
    await store.dispatch('rph-bonuses/getFaqContent', {})
  },

  computed: {
    faqContent() {
      return this.$store.getters['rph-bonuses/faqContent']
    },
    isAuth() {
      return this.$auth.loggedIn
    },
  },

  head() {
    return {
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Aurma DOS: копите баллы и покупайте с выгодой до 70% на маркетплейсе аптек Aurma.kz',
        },
        {
          hid: 'title',
          name: 'title',
          content: 'Программа лояльности Aurma.kz',
        },
      ],
      title: 'Программа лояльности Aurma.kz',
    }
  },

  methods: {
    openRegForm() {
      if (this.isAuth) {
        this.$router.push('/lekarstva-i-bady')
      } else {
        this.$store.commit('signin/SET_SIGNIN_ACTIVE', true)
        this.$store.commit('signin/SET_SIGNIN_TYPE', 'auth')
      }
    }
  }
})
